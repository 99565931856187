import React from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import SEO from "../components/seo"
import covidCover from "../images/covid-cover.png"

const Gracias = () => (
  <Layout>
    <SEO title="Gracias" />
    <div className="hero is-large">
      <div className="hero-body mainHeroCover">
        <div className="container has-text-centered">
          <div className="columns ">
            <div className="covid-header">
              <Fade delay={200}>
                <p className="land-about__subtitleAlt covid-pill">COVID-19</p>
                <h1 className="title land-about__title covid-title">Gracias</h1>
                <p className="covid-about">
                  En estos tiempos de crisis, nos sumamos al apoyo de las
                  empresas de la industria inmobiliaria ofreciendo nuestras
                  herramientas de administración de ventas de forma gratuita por
                  tres meses.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Gracias
